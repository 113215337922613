import React, { createContext, useContext, useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAppLoader, setIsAppLoader] = useState(false);
  const [allFoldersData, setAllFoldersData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [activeStatus, setActiveStatus] = useState(0);
  const [activeStatusText, setActiveStatusText] = useState(0);
  const [selectedFileName, setSelectedFileName] = useState([]);
  const [selectedTextFileName, setSelectedTextFileName] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileTextSelected, setFileTextSelected] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [audioDurations, setAudioDurations] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [audioTextData, setAudioTextData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [userName, setUserName] = useState(localStorage.getItem("username") || "");
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const [textData, setTextData] = useState([]);
  const [specificAudio, setSpecificAudio] = useState([]);
  const [specificText, setSpecificText] = useState([]);
  const [audioStatsData, setAudioStatsData] = useState([]);
  const [countdown, setCountdown] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [error, setError] = useState("");
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const storedUserData = localStorage.getItem("userData");
  const [charWidth, setChartWidth] = useState();
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [audioFileCount, setAudioFileCount] = useState(localStorage.getItem("audioFileCount") || 0);
  const [textFileCount, setTextFileCount] = useState(localStorage.getItem("textFileCount") || 0);
  const [averageAudioDuration, setAverageAudioDuration] = useState(
    localStorage.getItem("avgDuration") || 0
  );
  const [sessionTime, setSessionTime] = useState(localStorage.getItem("sessionTime") || null);
  const [sessionEndTime, setSessionEndTime] = useState(
    localStorage.getItem("sessionEndTime") || null
  );

  const [userData, setUserData] = useState(() => {
    try {
      return storedUserData
        ? typeof storedUserData === "string"
          ? JSON.parse(storedUserData)
          : storedUserData
        : {};
    } catch (error) {
      console.error("Error parsing storedUserData:", error);
      return {}; // Fallback to an empty object in case of error
    }
  });

  const [unprocessData, setUnprocessData] = useState([]);
  const [unprocessDataText, setUnprocessDataText] = useState([]);
  const [changeInTextData, setChangeInTextData] = useState(false);
  const [changeInAudioData, setChangeInAudioData] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);

  /***** Validation Checks ******/
  const isValidObjField = (obj) => {
    return Object.values(obj).every((value) => value.trim());
  };

  const updateError = (error, stateUpdater) => {
    stateUpdater(error);
    setTimeout(() => {
      stateUpdater("");
    }, 2500);
  };

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /***** Showing Toast/Alert ******/
  const showToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };

  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  const [guideButtonClicked, setGuideButtonClicked] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        changeInTextData,
        setChangeInTextData,
        fetchingLoading, setFetchingLoading,
        allFoldersData,
        setAllFoldersData,
        sessionEndTime,
        setSessionEndTime,
        guideButtonClicked,
        setGuideButtonClicked,
        changeInAudioData,
        setChangeInAudioData,
        showToast,
        isLoading,
        audioFileCount,
        setAudioFileCount,
        textFileCount,
        setTextFileCount,
        setIsLoading,
        isValidObjField,
        updateError,
        sessionTime,
        setSessionTime,
        userName,
        setUserName,
        error,
        setError,
        isErrorOpen,
        setIsErrorOpen,
        firstTimeLogin,
        setFirstTimeLogin,
        isAppLoader,
        setIsAppLoader,
        showPassword,
        setShowPassword,
        setAudioStatsData,
        activeStatus,
        setActiveStatus,
        activeStatusText,
        setActiveStatusText,
        averageAudioDuration,
        setAverageAudioDuration,
        selectedFileName,
        setSelectedFileName,
        selectedTextFileName,
        setSelectedTextFileName,

        fileSelected,
        setFileSelected,
        fileTextSelected,
        setFileTextSelected,
        userToken,
        setUserToken,
        fileToUpload,
        setFileToUpload,
        audioDurations,
        setAudioDurations,
        responseData,
        setResponseData,
        audioTextData,
        setAudioTextData,
        audioData,
        setAudioData,

        textData,
        setTextData,
        specificAudio,
        setSpecificAudio,
        specificText,
        setSpecificText,
        audioStatsData,
        countdown,
        setCountdown,
        userData,
        setUserData,
        charWidth,
        setChartWidth,
        unprocessData,
        setUnprocessData,
        unprocessDataText,
        setUnprocessDataText,
        tourOpen,
        setTourOpen,
      }}
    >
      {children}
      {/* Success Toast Component */}
      <Snackbar
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleToastClose}>
          {toastMessage}
        </Alert>
      </Snackbar>

      {/* Error Toast Component */}
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleErrorClose}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};

export const UserContext = () => useContext(AuthContext);

export default AuthProvider;
