import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../context/AuthContext";
import SplashScreen from "../components/SplashScreen/SplashScreen";

const PrivateRoutes = ({ children, ...rest }) => {
  const { userToken, setUserToken } = UserContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay for splash screen

    const sessionTime = localStorage.getItem("sessionTime");
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - sessionTime;

    if (timeElapsed > 86400000) { // 24 hours for session timout
      localStorage.removeItem("userToken");
      localStorage.removeItem("sessionTime");
      setUserToken(null);
    }

    return () => clearTimeout(timer);
  }, [setUserToken,navigate]);

  if (loading) {
    return <SplashScreen />;
  }

  return !userToken ? <Navigate to="/" /> : <Outlet />;
};

export default PrivateRoutes;
