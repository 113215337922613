import React, { Suspense, lazy } from "react";
import { TourProvider } from "@reactour/tour";
import landingSteps from "../components/TourSteps/landingPageSteps";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import { UserContext } from "../context/AuthContext";
import { useTour } from "@reactour/tour";
import SplashScreen from "../components/SplashScreen/SplashScreen";
const Home = lazy(() => import("../components/Home"));
const TextMain = lazy(() => import("../components/TextUpload/TextHome/main"));
const LandingPage = lazy(() => import("../components/LandingPage"));
const Header = lazy(() => import("../components/AnalyticalReports/Header"));
const AdminLogin = lazy(() => import("../components/Admin/Authentication/AdminLogin"));
const ResetPassword = lazy(() => import("../components/Admin/Authentication/ResetPassword"));
const OTPVerification = lazy(() => import("../components/Admin/Authentication/OTPVerification"));
const LatestReport = lazy(() => import("../components/LatestReportUI/LatestReport"));
const LatestTextReport = lazy(() =>
  import("../components/TextUpload/LatestTextReportUI/LatestTextReport")
);
const ForgetPassword = lazy(() => import("../components/Admin/Authentication/ForgetPassword"));
const SpecificFolderAudio = lazy(() => import("../components/HomeSection/SpecificFolderAudio"));
const Signup = lazy(() => import("../components/Admin/Register/Signup"));
const Terms = lazy(() => import("../components/Admin/Register/Terms"));
const Terms2 = lazy(() => import("../components/Admin/Register/Terms2"));
const SignupOTP = lazy(() => import("../components/Admin/Register/SignupOTP"));
const ErrorPage = lazy(() => import("../components/Admin/ErrorPage"));
const LandingWebsite = lazy(() => import("../components/LandingWebsite/LandingWebsite"));
const ContactUs = lazy(() => import("../components/ContactUs/ContactUs"));

function Stack() {
  const { setTourOpen } = UserContext();
  const { setIsOpen } = useTour();

  const handleCloseTour = () => {
    setTourOpen(false);
    setIsOpen(false);
  };

  return (
    <Router>
      <TourProvider
        showBadge={false}
        position="top"
        placement="bottom"
        className="reactour-popup"
        showDots={false}
        steps={landingSteps}
        onClickClose={handleCloseTour}
        onClickMask={handleCloseTour}
        styles={{
          popover: (base) => ({
            ...base,
            "--reactour-accent": "#43addb",
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
            color: "white",
          }),
        }}
      >
        <Suspense fallback={<SplashScreen />}>
          <Routes>
            {/* Private route for login */}
            <Route element={<PrivateRoutes />}>
              <Route path="/AudioPage" element={<Home />} />
              <Route path="/TextPage" element={<TextMain />} />
              <Route path="/selectedAudio" element={<LatestReport />} />
              <Route path="/selectedText" element={<LatestTextReport />} />
              <Route path="/AudioReport" element={<Header />} />
              <Route path="/folder_audio" element={<SpecificFolderAudio />} />
              <Route path="/landing" element={<LandingPage />} />
            </Route>
            <Route path="/" element={<LandingWebsite />} />
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup-otp" element={<SignupOTP />} />
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route path="/otp_verification" element={<OTPVerification />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/terms_and_conditions" element={<Terms />} />
            <Route path="/terms2" element={<Terms2 />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </TourProvider>
    </Router>
  );
}

export default Stack;
